<template>
  <div>
    <div class="contents">
       <submenu curbg=1></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="商品名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.name" clearable  style="width:190px"
                    placeholder="请输入商品名称"></el-input>
                </el-form-item>

                <el-form-item label="商品编码" style="margin-bottom:10px">

                  <el-input v-model="searchForm.skuCode" clearable  style="width:190px"
                    placeholder="请输入商品编码"></el-input>
                </el-form-item>

                <el-form-item label="商品条码" style="margin-bottom:10px">

                  <el-input v-model="searchForm.barcode" clearable  style="width:190px"
                    placeholder="请输入商品条码"></el-input>
                </el-form-item>

                <el-form-item label="货主" style="margin-bottom:10px">

                  <el-input v-model="searchForm.customerName" clearable  style="width:190px"
                    placeholder="请输入货主"></el-input>
                </el-form-item>

                <el-form-item label="供应商" style="margin-bottom:10px">

                  <el-input v-model="searchForm.supplierName" clearable  style="width:190px"
                    placeholder="请输入供应商"></el-input>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>
              <el-button type="primary" class="tbBtn" icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button>

            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>


        <vxe-column
            field="image"
            title="商品图片"
            min-width="80"
        >
          <template slot-scope="scope">
                <el-image
                  :src="scope.row.image && scope.row.image.length > 0 ? scope.row.image : backImg"
                >
                </el-image>
          </template>
        </vxe-column>
        <vxe-column
            field="name"
            title="商品名称"
            min-width="180"
        >
          <template slot-scope="scope">
                {{scope.row.name}}
          </template>
        </vxe-column>
        <vxe-column
            field="spec"
            title="规格"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.spec}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuCode"
            title="商品编码"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.skuCode}}
          </template>
        </vxe-column>
        <vxe-column
            field="barcode"
            title="商品条码"
            min-width="140"
        >
          <template slot-scope="scope">
                {{scope.row.barcode}}
          </template>
        </vxe-column>

        <vxe-column
            field="customerSkuCode"
            title="客户商品编码"
            min-width="140"
        >
          <template slot-scope="scope">
                {{scope.row.customerSkuCode}}
          </template>
        </vxe-column>

        <vxe-column
            field="supplierSkuCode"
            title="供应商商品编码"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.supplierSkuCode}}
          </template>
        </vxe-column>

        <vxe-column
            field="customerName"
            title="货主"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.customerName}}
          </template>
        </vxe-column>
        <vxe-column
            field="supplierName"
            title="供应商"
            min-width="60"
        >
          <template slot-scope="scope">
                {{scope.row.supplierName}}
          </template>
        </vxe-column>
        <vxe-column
            field="costPrice"
            title="成本价（元）"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.costPrice}}
          </template>
        </vxe-column>
        <vxe-column
            field="spuCode"
            title="款式编码"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.spuCode}}
          </template>
        </vxe-column>
        <vxe-column
            field="categoryName"
            title="商品类目"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.categoryName}}
          </template>
        </vxe-column>

        <vxe-column
            field="unitName"
            title="计量单位"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.unitName}}
          </template>
        </vxe-column>

        <vxe-column
            field="brandName"
            title="品牌"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.brandName}}
          </template>
        </vxe-column>
        <vxe-column
            field="propertyName"
            title="产品属性"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.propertyName}}
          </template>
        </vxe-column>
        <vxe-column
            field="temperatureTypeName"
            title="存放温层"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.temperatureTypeName}}
          </template>
        </vxe-column>
        <vxe-column
            field="volume"
            title="体积(立方厘米)"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.volume}}
          </template>
        </vxe-column>
        <vxe-column
            field="weight"
            title="重量（KG）"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.weight}}
          </template>
        </vxe-column>
        <vxe-column
            field="length"
            title="长（厘米）"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.length}}
          </template>
        </vxe-column>
        <vxe-column
            field="width"
            title="宽（厘米）"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.width}}
          </template>
        </vxe-column>
        <vxe-column
            field="height"
            title="高（厘米）"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.height}}
          </template>
        </vxe-column>
        <vxe-column
            field="acceptDays"
            title="允收天数"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.acceptDays}}
          </template>
        </vxe-column>
        <vxe-column
            field="expiryDate"
            title="保质期（天）"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.expiryDate}}
          </template>
        </vxe-column>
        <vxe-column
            field="status"
            title="状态"
            min-width="50"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsSkuStatus'][scope.row.status]}}
          </template>
        </vxe-column>
        <vxe-column
            field="createTime"
            title="创建时间"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.createTime}}
          </template>
        </vxe-column>

        <vxe-column title="操作" fixed="right" width="160">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>

                  <el-button class="c-button" @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>

             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <sku-add ref="wmsSkuAdd" @success="getTopSearch"></sku-add>
    <sku-detail ref="wmsSkuDetail" @success="getTopSearch"></sku-detail>
    <sku-edit ref="wmsSkuEdit" @success="getTopSearch"></sku-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import submenu from "@/components/submenu"
import backImg from "@/assets/logofang.png"
import skuAdd from "./wmsSkuAdd"
import skuDetail from "./wmsSkuDetail"
import skuEdit from "./wmsSkuEdit"

import { pageWmsSku, detailWmsSku, delWmsSku, batchDeleteWmsSku } from "@/api/wms/wmsSku"
import { getEnums } from "@/api/common"
import { listWmsCustomer } from "@/api/wms/wmsCustomer"

import { listWmsSupplier } from "@/api/wms/wmsSupplier"

import { listWmsSkuCategory } from "@/api/wms/wmsSkuCategory"

import { listWmsUnit } from "@/api/wms/wmsUnit"


import { listWmsSkuBrand } from "@/api/wms/wmsSkuBrand"

import { listWmsProperty } from "@/api/wms/wmsProperty"

import { listWmsTemperature } from "@/api/wms/wmsTemperature"


let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsSku',
  components: { submenu, skuAdd, skuDetail, skuEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      backImg: backImg,
      enums: {
        WmsSkuStatus: [],
      },
      enumMaps: {},
        optionsOfWmsCustomer: [],

        optionsOfWmsSupplier: [],

        optionsOfWmsSkuCategory: [],

        optionsOfWmsUnit: [],


        optionsOfWmsSkuBrand: [],

        optionsOfWmsProperty: [],

        optionsOfWmsTemperature: [],

      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsSku(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsSkuAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsSkuDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsSkuEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSupplier(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSupplier(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSupplier = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSkuCategory(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSkuCategory(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSkuCategory = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsUnit(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsUnit(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsUnit = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },


    listWmsSkuBrand(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSkuBrand(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSkuBrand = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsProperty(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsProperty(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsProperty = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsTemperature(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsTemperature(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsTemperature = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsSku(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.listWmsCustomer()

    this.listWmsSupplier()

    this.listWmsSkuCategory()

    this.listWmsUnit()


    this.listWmsSkuBrand()

    this.listWmsProperty()

    this.listWmsTemperature()

    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>