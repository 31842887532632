import request from '@/api/request.js'

/**
 * @Description: 产品属性
 */

// 分页查询
export function pageWmsProperty(data) {
    return request({
        url: '/api/wmsProperty/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsProperty(data) {
    return request({
        url: '/api/wmsProperty/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsProperty(data) {
    return request({
        url: '/api/wmsProperty/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsProperty(data) {
    return request({
        url: '/api/wmsProperty/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsProperty(data) {
    return request({
        url: '/api/wmsProperty/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsProperty(data) {
    return request({
        url: '/api/wmsProperty/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsProperty(data) {
    return request({
        url: '/api/wmsProperty/batchDelete',
        method: 'POST',
        data
    })
}

