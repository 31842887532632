
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="添加商品"
    :visible.sync="visible"
    width="1000px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        <el-col :span="8">

            <el-form-item label="商品图片" prop="image">
                          <el-upload
                            class="avatar-uploader"
                            action="string"
                            :http-request="upload"
                            :show-file-list="false"
                            :on-success="(url) => row.image=url "
                            >
                            <img v-if="row.image" :src="row.image" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
            </el-form-item>
        </el-col>

        <el-col :span="8">

                <el-form-item label="商品名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入商品名称" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="8">

                <el-form-item label="规格" prop="spec">
              <el-input v-model="row.spec" placeholder="请输入规格" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">

                <el-form-item label="商品编码" prop="skuCode">
              <el-input v-model="row.skuCode" placeholder="商品编码系统自动生成" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="8">

                <el-form-item label="商品条码" prop="barcode">
              <el-input v-model="row.barcode" placeholder="请输入商品条码" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="8">

            <el-form-item label="货主" prop="customerId">
                <el-select ref="customerId"
                                v-model="row.customerId"
                                prop="customerId"
                                placeholder="请选择货主"
                                clearable
                                filterable
                                :filter-method="listWmsCustomer"
                            >
                            <el-option
                                v-for="item in optionsOfWmsCustomer"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">

            <el-form-item label="供应商" prop="supplierId">
                <el-select ref="supplierId"
                                v-model="row.supplierId"
                                prop="supplierId"
                                placeholder="请选择供应商"
                                clearable
                                filterable
                                :filter-method="listWmsSupplier"
                            >
                            <el-option
                                v-for="item in optionsOfWmsSupplier"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>

        <el-col :span="8">

          <el-form-item label="成本价（元）" prop="costPrice">
              <el-input-number v-model="row.costPrice"
                placeholder="请输入成本价（元）" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>

        <el-col :span="8">

                <el-form-item label="款式编码" prop="spuCode">
              <el-input v-model="row.spuCode" placeholder="请输入款式编码" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">

            <el-form-item label="商品类目" prop="categoryId">
                <el-select ref="categoryId"
                                v-model="row.categoryId"
                                prop="categoryId"
                                placeholder="请选择商品类目"
                                clearable
                                filterable
                                :filter-method="listWmsSkuCategory"
                            >
                            <el-option
                                v-for="item in optionsOfWmsSkuCategory"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>

        <el-col :span="8">

                <el-form-item label="供应商商品编码" prop="supplierSkuCode">
              <el-input v-model="row.supplierSkuCode" placeholder="请输入供应商商品编码" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="8">

                <el-form-item label="客户商品编码" prop="customerSkuCode">
              <el-input v-model="row.customerSkuCode" placeholder="请输入客户商品编码" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">

            <el-form-item label="计量单位" prop="unitId">
                <el-select ref="unitId"
                                v-model="row.unitId"
                                prop="unitId"
                                placeholder="请选择计量单位"
                                clearable
                                filterable
                                :filter-method="listWmsUnit"
                            >
                            <el-option
                                v-for="item in optionsOfWmsUnit"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>

        <el-col :span="8">

            <el-form-item label="品牌" prop="brandId">
                <el-select ref="brandId"
                                v-model="row.brandId"
                                prop="brandId"
                                placeholder="请选择品牌"
                                clearable
                                filterable
                                :filter-method="listWmsSkuBrand"
                            >
                            <el-option
                                v-for="item in optionsOfWmsSkuBrand"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>

            </el-row>

            <el-row>
            <el-col>
            <el-form-item label="多单位" prop="units">
    <vxe-toolbar perfect>
        <template #buttons>
            <el-button type="text" icon="el-icon-plus" @click="() => row.units.push({})">新增</el-button>
        </template>
    </vxe-toolbar>

<vxe-table
        :data="row.units"
        empty-text=""
        :scroll-y="{enabled: false}"
        :edit-config="{trigger: 'click', mode: 'cell'}"
        ref="unitsTable"
>

            <vxe-column field="id" title="ID" :visible="false" ></vxe-column>

        <vxe-column field="unitId" title="副单位" >
            <template slot-scope="scope">
                    <el-select ref="unitId"
                               :disabled="false"
                               v-model="scope.row.unitId"
                               prop="unitId"
                               placeholder="请选择副单位"
                               clearable
                               filterable
                               :filter-method="listWmsUnit"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsUnit"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>




        <vxe-column field="assistUnitId" title="辅助计量" >
            <template slot-scope="scope">
                    <el-select ref="assistUnitId"
                               :disabled="false"
                               v-model="scope.row.assistUnitId"
                               prop="assistUnitId"
                               placeholder="请选择辅助计量"
                               clearable
                               filterable
                               :filter-method="listWmsUnit"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsUnit"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>


        <vxe-column field="multiple" title="换算倍数" >
            <template slot-scope="scope">
                    <el-input v-model="scope.row.multiple" placeholder="请输入换算倍数" clearable
                              :disabled="false"
                              :style="{width: '125px'}"></el-input>
            </template>
        </vxe-column>


        <vxe-column field="costPrice" title="成本价(元)" >
            <template slot-scope="scope">
                    <el-input-number v-model="scope.row.costPrice"
                                     :disabled="false"
                                     placeholder="请输入成本价(元)" clearable
                                     :step="0.001"
                                     :precision="3"
                                     :controls="false"
                                     :style="{width: '120px'}"></el-input-number>
            </template>
        </vxe-column>


        <vxe-column field="code" title="副单位商品编码" >
            <template slot-scope="scope">
                    <el-input v-model="scope.row.code" placeholder="请输入编码" clearable
                              :disabled="false"
                              :style="{width: '130px'}"></el-input>
            </template>
        </vxe-column>

    <vxe-column title="操作">
        <template slot-scope="scope">
            <el-button type="text" icon="el-icon-delete" @click="() => row.units.splice(scope.$index, 1)">删除</el-button>
        </template>
    </vxe-column>
</vxe-table>            </el-form-item>
            </el-col>
            </el-row>

<el-row>
            <el-col>
        </el-col>

            </el-row>

            <el-row>

        <el-col :span="8">

            <el-form-item label="产品属性" prop="propertyId">
                <el-select ref="propertyId"
                                v-model="row.propertyId"
                                prop="propertyId"
                                placeholder="请选择产品属性"
                                clearable
                                filterable
                                :filter-method="listWmsProperty"
                            >
                            <el-option
                                v-for="item in optionsOfWmsProperty"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>

        <el-col :span="8">

            <el-form-item label="存放温层" prop="temperatureTypeId">
                <el-select ref="temperatureTypeId"
                                v-model="row.temperatureTypeId"
                                prop="temperatureTypeId"
                                placeholder="请选择存放温层"
                                clearable
                                filterable
                                :filter-method="listWmsTemperature"
                            >
                            <el-option
                                v-for="item in optionsOfWmsTemperature"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>

        <el-col :span="8">

          <el-form-item label="体积(立方厘米)" prop="volume">
              <el-input-number v-model="row.volume"
                placeholder="请输入体积(立方厘米)" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">

          <el-form-item label="重量（KG）" prop="weight">
              <el-input-number v-model="row.weight"
                placeholder="请输入重量（KG）" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>

        <el-col :span="8">

          <el-form-item label="长（厘米）" prop="length">
              <el-input-number v-model="row.length"
                placeholder="请输入长（厘米）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>

        <el-col :span="8">

          <el-form-item label="宽（厘米）" prop="width">
              <el-input-number v-model="row.width"
                placeholder="请输入宽（厘米）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">

          <el-form-item label="高（厘米）" prop="height">
              <el-input-number v-model="row.height"
                placeholder="请输入高（厘米）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>

        <el-col :span="8">

          <el-form-item label="允收天数" prop="acceptDays">
              <el-input-number v-model="row.acceptDays"
                placeholder="请输入允收天数" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>

        <el-col :span="8">

          <el-form-item label="保质期（天）" prop="expiryDate">
              <el-input-number v-model="row.expiryDate"
                placeholder="请输入保质期（天）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="8">

            <el-form-item label="状态" prop="status">
                <el-select v-model="row.status"
                clearable
                placeholder="请选择状态">
                    <el-option
                          v-for="item in enums['WmsSkuStatus']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
            </el-form-item>
        </el-col>

        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { addWmsSku } from "@/api/wms/wmsSku"

import { listWmsCustomer } from "@/api/wms/wmsCustomer"

import { listWmsSupplier } from "@/api/wms/wmsSupplier"

import { listWmsSkuCategory } from "@/api/wms/wmsSkuCategory"

import { listWmsSku } from "@/api/wms/wmsSku"
import { listWmsUnit } from "@/api/wms/wmsUnit"
import { listWmsSkuBrand } from "@/api/wms/wmsSkuBrand"

import { listWmsProperty } from "@/api/wms/wmsProperty"

import { listWmsTemperature } from "@/api/wms/wmsTemperature"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            image: null,
            name: null,
            spec: null,
            skuCode: null,
            barcode: null,
            customerId: null,
            customerName: null,
            supplierId: null,
            supplierName: null,
            costPrice: null,
            spuCode: null,
            categoryName: null,
            categoryId: null,
            supplierSkuCode: null,
            customerSkuCode: null,
            unitId: null,
            unitName: null,
            units: [],
            splitControl: 0,
            splitUnitId: null,
            splitUnitName: null,
            splitNumber: null,
            brandId: null,
            brandName: null,
            propertyId: null,
            propertyName: null,
            temperatureTypeId: null,
            temperatureTypeName: null,
            volume: null,
            weight: null,
            length: null,
            width: null,
            height: null,
            acceptDays: null,
            expiryDate: null,
            status: 0,
      },
      rules: {
            name: [
                { required: true, message: '请输入商品名称!'},
            ],
            unitId: [
                { required: true, message: '请输入计量单位!'},
            ],
            splitControl: [
                { required: true, message: '请输入是否拆零!'},
            ],
            status: [
                { required: true, message: '请输入状态!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsCustomer: [],

        optionsOfWmsSupplier: [],

        optionsOfWmsSkuCategory: [],

        optionsOfWmsUnit: [],

        optionsOfWmsSku: [],
        optionsOfWmsUnit: [],

        optionsOfWmsSkuBrand: [],

        optionsOfWmsProperty: [],

        optionsOfWmsTemperature: [],

    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsCustomer()
    this.listWmsSupplier()
    this.listWmsSkuCategory()
    this.listWmsUnit()
    this.listWmsSku()
    this.listWmsUnit()
    this.listWmsSkuBrand()
    this.listWmsProperty()
    this.listWmsTemperature()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsSkuStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen() {
        this.visible = true
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSupplier(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSupplier(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSupplier = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSkuCategory(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSkuCategory(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSkuCategory = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsUnit(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsUnit(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsUnit = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSku(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSku(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSku = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsUnit(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsUnit(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsUnit = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSkuBrand(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSkuBrand(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSkuBrand = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsProperty(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsProperty(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsProperty = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsTemperature(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsTemperature(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsTemperature = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        addWmsSku(param).then(res => {
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
