import request from '@/api/request.js'

/**
 * @Description: 商品分类
 */

// 分页查询
export function pageWmsSkuCategory(data) {
    return request({
        url: '/api/wmsSkuCategory/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsSkuCategory(data) {
    return request({
        url: '/api/wmsSkuCategory/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsSkuCategory(data) {
    return request({
        url: '/api/wmsSkuCategory/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsSkuCategory(data) {
    return request({
        url: '/api/wmsSkuCategory/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsSkuCategory(data) {
    return request({
        url: '/api/wmsSkuCategory/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsSkuCategory(data) {
    return request({
        url: '/api/wmsSkuCategory/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsSkuCategory(data) {
    return request({
        url: '/api/wmsSkuCategory/batchDelete',
        method: 'POST',
        data
    })
}

