<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="商品详情"
    :visible.sync="visible"
    width="1000px"
    >

      <el-descriptions class="margin-top" :column="3"
      border>


            <el-descriptions-item label="ID">
            {{ row.id }}
            </el-descriptions-item>

            <el-descriptions-item label="商品图片">
              <el-image
                :src="row.image" >
              </el-image>
            </el-descriptions-item>

            <el-descriptions-item label="商品名称">
            {{ row.name }}
            </el-descriptions-item>

            <el-descriptions-item label="规格">
            {{ row.spec }}
            </el-descriptions-item>

            <el-descriptions-item label="商品编码">
            {{ row.skuCode }}
            </el-descriptions-item>

            <el-descriptions-item label="商品条码">
            {{ row.barcode }}
            </el-descriptions-item>


            <el-descriptions-item label="货主">
            {{ row.customerName }}
            </el-descriptions-item>


            <el-descriptions-item label="供应商">
            {{ row.supplierName }}
            </el-descriptions-item>

            <el-descriptions-item label="成本价（元）">
            {{ row.costPrice }}
            </el-descriptions-item>

            <el-descriptions-item label="款式编码">
            {{ row.spuCode }}
            </el-descriptions-item>

            <el-descriptions-item label="商品类目">
            {{ row.categoryName }}
            </el-descriptions-item>


            <el-descriptions-item label="供应商商品编码">
            {{ row.supplierSkuCode }}
            </el-descriptions-item>

            <el-descriptions-item label="客户商品编码">
            {{ row.customerSkuCode }}
            </el-descriptions-item>


            <el-descriptions-item label="计量单位">
            {{ row.unitName }}
            </el-descriptions-item>

                <el-descriptions-item label="多单位">
<el-table
        :data="row.units"
        ref="unitsTable"
        stripe
        border
>




        <el-table-column prop="unitName" label="副单位" >
        </el-table-column>


        <el-table-column prop="assistUnitName" label="辅助计量" >
        </el-table-column>

        <el-table-column prop="multiple" label="换算倍数" >
        </el-table-column>

        <el-table-column prop="costPrice" label="成本价(元)" >
        </el-table-column>

        <el-table-column prop="code" label="包装编码" >
        </el-table-column>

</el-table>
              </el-descriptions-item>


            <el-descriptions-item label="品牌">
            {{ row.brandName }}
            </el-descriptions-item>


            <el-descriptions-item label="产品属性">
            {{ row.propertyName }}
            </el-descriptions-item>


            <el-descriptions-item label="存放温层">
            {{ row.temperatureTypeName }}
            </el-descriptions-item>

            <el-descriptions-item label="体积(立方厘米)">
            {{ row.volume }}
            </el-descriptions-item>

            <el-descriptions-item label="重量（KG）">
            {{ row.weight }}
            </el-descriptions-item>

            <el-descriptions-item label="长（厘米）">
            {{ row.length }}
            </el-descriptions-item>

            <el-descriptions-item label="宽（厘米）">
            {{ row.width }}
            </el-descriptions-item>

            <el-descriptions-item label="高（厘米）">
            {{ row.height }}
            </el-descriptions-item>

            <el-descriptions-item label="允收天数">
            {{ row.acceptDays }}
            </el-descriptions-item>

            <el-descriptions-item label="保质期（天）">
            {{ row.expiryDate }}
            </el-descriptions-item>

            <el-descriptions-item label="状态" v-if="enumMaps['WmsSkuStatus']">
                 {{enumMaps['WmsSkuStatus'][row.status]}}
            </el-descriptions-item>


            <el-descriptions-item label="创建时间">
            {{ row.createTime }}
            </el-descriptions-item>




      </el-descriptions>


      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detailWmsSku } from "@/api/wms/wmsSku"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            image: '',
            name: '',
            spec: '',
            skuCode: '',
            barcode: '',
            customerId: '',
            customerName: '',
            supplierId: '',
            supplierName: '',
            costPrice: '',
            spuCode: '',
            categoryName: '',
            categoryId: '',
            supplierSkuCode: '',
            customerSkuCode: '',
            unitId: '',
            unitName: '',
            units: '',
            splitControl: '',
            splitUnitId: '',
            splitUnitName: '',
            splitNumber: '',
            brandId: '',
            brandName: '',
            propertyId: '',
            propertyName: '',
            temperatureTypeId: '',
            temperatureTypeName: '',
            volume: '',
            weight: '',
            length: '',
            width: '',
            height: '',
            acceptDays: '',
            expiryDate: '',
            status: '',
            createTime: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsSkuStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detailWmsSku(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style scoped>
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
